import { Auth } from 'aws-amplify'
import React, { createContext, useEffect, useMemo, useState } from 'react'

export const AccountContext = createContext()

const AuthProvider = ({children}) => {
    const [userProfile, setUserProfile] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const getUserProfile = async () => {
        try {
            const currentUser = await Auth.currentAuthenticatedUser();
            for(var i=0; i<currentUser.signInUserSession.accessToken.payload["cognito:groups"].length; i++) {
                if(currentUser.signInUserSession.accessToken.payload["cognito:groups"][i] === 'admin') {
                    setUserProfile(await Auth.currentAuthenticatedUser())
                }
            }
            setLoaded(true)
        }
        catch(err) {
            setLoaded(true)
        }
    }

    useEffect(() => {
        if(!loaded) {
            getUserProfile()
        }
    }, [])

    const value = useMemo(() => ({loaded, userProfile, getUserProfile}), [loaded, userProfile, getUserProfile])
    
    return (
        loaded ? 
        <AccountContext.Provider value={value}>
            {children}
        </AccountContext.Provider>

        :

        <div></div>
    )
}

export default AuthProvider