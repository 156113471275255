import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import React from 'react';
import Layout from './components/Layout';
import AuthProvider from './auth/AuthProvider';
import AuthGuard from './auth/AuthGuard';
import LoginGuard from './auth/LoginGuard';
import Chat from './pages/Chat';

const Login = React.lazy(() => import("./pages/Login"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const User = React.lazy(() => import("./pages/User"));
const UserDetails = React.lazy(() => import("./pages/User/UserDetails"));
const Instructor = React.lazy(() => import("./pages/Instructor"));
const InstructorDetails = React.lazy(() => import("./pages/Instructor/InstructorDetails"));
const InstructorProduct = React.lazy(() => import("./pages/Instructor/InstructorProduct"));
const Settings = React.lazy(() => import("./pages/Settings"));
const TradeOfTheWeek = React.lazy(() => import("./pages/TradeOfTheWeek"));

function App() {
	const location = useLocation()
  
	return (
        <AuthProvider>
			<Routes location={location} key={location.pathname}>
				<Route element={<AuthGuard />}>
					<Route exact path="/" element={<Layout />}>
						<Route
							index
							element={
							<React.Suspense fallback={<div>...</div>}>
								<Dashboard />
							</React.Suspense>
							}
						/>
                        <Route path="/user" element={
                            <React.Suspense fallback={<div>...</div>}>
                            	<User />
                            </React.Suspense>
                        }/>
                        <Route path="/user/:id" element={
                            <React.Suspense fallback={<div>...</div>}>
                            	<UserDetails />
                            </React.Suspense>
                        }/>
                        <Route path="/instructor" element={
                            <React.Suspense fallback={<div>...</div>}>
                            	<Instructor />
                            </React.Suspense>
                        }/>
                        <Route path="/instructor/:id" element={
                            <React.Suspense fallback={<div>...</div>}>
                            	<InstructorDetails />
                            </React.Suspense>
                        }/>
                        <Route path="/instructor/:id/:link" element={
                            <React.Suspense fallback={<div>...</div>}>
                            	<InstructorProduct />
                            </React.Suspense>
                        }/>
                        <Route path="/settings" element={
                            <React.Suspense fallback={<div>...</div>}>
                            	<Settings />
                            </React.Suspense>
                        }/>
                        <Route path="/trade-of-the-week" element={
                            <React.Suspense fallback={<div>...</div>}>
                            	<TradeOfTheWeek />
                            </React.Suspense>
                        }/>
					</Route>
					<Route exact path="/chat/:link" element={<Chat />}>

					</Route>
				</Route>

				{/* Public Routes */}
				<Route element={<LoginGuard />}>

					<Route exact path="/login" element={
						<React.Suspense fallback={<div>...</div>}>
							<Login />
						</React.Suspense>
					}/>
				</Route>
			</Routes>
		</AuthProvider>
	)
}

export default App;
