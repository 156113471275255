import React, { useEffect, useReducer, useState } from 'react'
import { getInstructorProductChatToken } from '../../graphql/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { useParams } from 'react-router-dom'

const initialState = {
    message: ''
}

function reducer(state, action) {
    switch(action.type) {
        case 'SET_INPUT':
            return {...state, [action.key]: action.value}
        case 'SET_INITIAL':
            return {...state, ...initialState}
        default:
            return state
    }
}  

const Chat = () => {
    const {link} = useParams()
    const [chatToken, setChatToken] = useState(null)
    const [chatReady, setChatReady] = useState(false)


    const [webhookConnection, setWebhookConnection] = useState(null)

    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState(null)

    const [state, dispatch] = useReducer(reducer, initialState)

    async function getChatToken() {
        try {
            const {data} = await API.graphql({...graphqlOperation(getInstructorProductChatToken, {link: link}), authMode: 'AMAZON_COGNITO_USER_POOLS'})
            if(data && data.getInstructorProductChatToken.token) {
                setChatToken(data.getInstructorProductChatToken.token)
                setTimeout(function () {
                    getChatToken()
                }, 3420000); 
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getChatToken()
    }, [])


    function onChange(e) {
        dispatch({type: 'SET_INPUT', key: e.target.name, value: e.target.value})
    }
      


    useEffect(() => {
        if(chatToken) {
            if(webhookConnection) webhookConnection.close()
            const chatEndpoint = 'wss://edge.ivschat.us-east-1.amazonaws.com'
    
            const connection = new WebSocket(chatEndpoint, chatToken)

            setChatReady(true)

            connection.addEventListener('message', (event) => {
                const data = JSON.parse(event.data)
                if(data.Type === 'MESSAGE') {
                    setNewMessage({
                        name: data.Sender.Attributes.name,
                        content: data.Content
                    })
                }
            })
            connection.addEventListener('open', (event) => {
                setChatReady(true)
            })

            connection.addEventListener('close', (event) => {
                setChatReady(false)
            })


            setWebhookConnection(connection)
        }

    }, [chatToken])

    useEffect(() => {
        if(newMessage) {
            setMessages([
                {
                    name: newMessage.name,
                    content: newMessage.content
                },
                ...messages
            ])
        }
    }, [newMessage])

    function sendMessage(e) {
        e.preventDefault()
        
        if(state.message.trim(' ')) {
            webhookConnection.send(JSON.stringify({
                'Action': 'SEND_MESSAGE',
                'Content': state.message
            }))
    
            dispatch({type: 'SET_INITIAL'})
        }
    }

    return (
        <div className="fixed top-0 left-0 bottom-0 right-0">
            <div className="flex flex-col py-3 px-3 gap-2 lg:absolute lg:top-0 lg:left-0 w-full h-full">
                <div className="h-full overflow-y-scroll bg-white w-full text-xs p-2 rounded-sm flex flex-col-reverse border border-black">
                    {messages.length > 0 && messages.map((message, key) => (
                        <p key={`message-${key}`}>
                            <span className="font-bold">{message.name}:</span> {message.content}
                        </p>
                    ))}
                </div>
                {chatReady &&
                    <form onSubmit={sendMessage} className="flex flex-col gap-2">
                        <input type="text" name="message" className="outline-none p-1 text-sm rounded-sm border border-primary" placeholder="Write a message..." onChange={onChange} value={state.message}/>
                            <button 
                                className={`text-center text-sm leading-normal text-white py-1.5 px-14 bg-primary rounded-sm border border-primary hover:font-semibold hover:bg-primary-light`}
                            >Send</button>
                    </form>
                }
            </div>
        </div>
    )
}

export default Chat