import React from 'react'

const SidebarHeader = ({title, children}) => {
  return (
    <ul>
      <li>
          <div className="mb-3.5">
              <span className="text-primary font-bold text-base">{title}</span>
          </div>
          <ul className="flex flex-col gap-y-4 justify-center">
              {children}
          </ul>
      </li>
    </ul>
  )
}

export default SidebarHeader