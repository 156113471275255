
import React from 'react'
import {motion} from 'framer-motion'
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Topbar = ({sidebarVisible, setSidebarVisible, sidebarMobileVisible, setSidebarMobileVisible}) => {
  const { width, height } = useWindowDimensions();

  return (
    <motion.div className="w-full h-[4.25rem] flex flex-row justify-between border-b border-soft-grey py-3.5 px-7">
        <button className="h-6 aspect-square my-auto cursor-pointer" 
          onClick={() => {
            width >= 1024 ?
              (sidebarVisible ? setSidebarVisible(false) : setSidebarVisible(true)) :
              (sidebarMobileVisible ? setSidebarMobileVisible(false) : setSidebarMobileVisible(true))
          }}
        >
            <img src="/icons/hamburger.svg" alt="" />
        </button>
      <img src="http://via.placeholder.com/120" alt="" className="h-full aspect-square" />
    </motion.div>
  )
}

export default Topbar