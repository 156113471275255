import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AccountContext } from "./AuthProvider";

const LoginGuard = () => {
    const { loaded, userProfile } = useContext(AccountContext)
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";
    
    return (
        loaded ? 
            !userProfile ? <Outlet /> : <Navigate to="/" state={{ from: location }} replace />
            
            :

            <div></div>
    );
}

export default LoginGuard;