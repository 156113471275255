import { useContext } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AccountContext } from "./AuthProvider";

const AuthGuard = () => {
    const { loaded, userProfile } = useContext(AccountContext)
    const location = useLocation();

    return (
        loaded ? 
            userProfile ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />
            
            :

            <></>
    );
}

export default AuthGuard;